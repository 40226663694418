import React from 'react';

const xuexi = () => {
  window.open('https://shangzhibo.tv/watch/6283301')
}
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: 'https://storage.dinner.lyzg.ink/20200304201803.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          onClick: () => {
            let href = 'https://kefu.ziyun.com.cn/vclient/chat/?websiteid=158436&wc=940471';
            window.open(
              href,
              'MCNopen',
              'resizable=no,status=no,location=no,toolbar=no,menubar=no,fullscreen=no,scrollbars=no,dependent=no,height=529, width=700, top=265,left=645',
            );
            return false;
          },
          children: [
            {
              children: (
                <>
                  <p>在线客服</p>
                </>
              ),
              name: 'text',
            },
          ],
        }
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <>
                  <p>联系我们</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <>
                  <p>帮助中心</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <>
                  <p>400-119-3009</p>
                </>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title k62ilr6s8yf-editor_css',
    children: 'https://storage.dinner.lyzg.ink/20200304201801.png',
  },
  content: {
    className: 'banner0-content',
    children: (
      <>
        <p>中能魔力线上经纪人（员工）内部管理系统&nbsp;&nbsp;</p>
      </>
    ),
  },
  button: {
    className: 'banner0-button',
    onClick: () => {
      window.open('https://live.mcn-open.com/')
    },
    style: { marginRight: '20px' },
    children: (
      <>
        <p>系统一</p>
      </>
    ),
  },
  button1: {
    className: 'banner0-button',
    style: { margin: '20px' },
    onClick: () => {
      window.open('https://live.mcn-union.com/')
    },
    children: (
      <>
        <p>系统二</p>
      </>
    ),
  },
  button3: {
    className: 'banner0-button',
    style: { margin: '20px' },
    onClick: () => {
      window.open('https://hj.mcn-open.com/')
    },
    children: (
      <>
        <p>系统三</p>
      </>
    ),
  },
  button4: {
    className: 'banner0-button',
    style: { marginLeft: '20px' },
    onClick: () => {
      window.open('https://tt.mcn-open.com/')
    },
    children: (
      <>
        <p>系统四</p>
      </>
    ),
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>系统特点</p>
          </>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p>收益结算</p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p>结算秒到账、真正打破信任危机</p>
                </>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p>数据逻辑</p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p>主播管理轻松、直观，为业务助力</p>
                </>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p>全程指导</p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p>帮助新人和小团队快速成长</p>
                </>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Banner40DataSource = {
  wrapper: { className: 'home-page-wrapper banner4 k62tpz7izk6-editor_css' },
  page: { className: 'home-page banner4-page' },
  childWrapper: {
    className: 'banner4-title-wrapper k62uvejuil-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>培训指导</p>
          </>
        ),
        className: 'banner4-title k62tpn2k2sm-editor_css',
      },
      {
        name: 'content',
        className: 'banner4-content',
        children: (
          <>
            <p>从小白到高手、最前端的思维启迪、助力用户成长</p>
          </>
        ),
      },
      {
        name: 'button',
        children: {
          type: 'primary',
          onClick: () => xuexi(),
          children: (
            <>
              <p>进入学习</p>
            </>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner4-image k62trew35q-editor_css',
    onClick: () => xuexi(),
    children:
      'https://storage.dinner.lyzg.ink/20200305190501.jpg',
  },
};
export const Content30DataSource = {
  wrapper: {
    className: 'home-page-wrapper content3-wrapper k62j260ioe-editor_css',
  },
  page: { className: 'home-page content3 k62k00g2706-editor_css' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>专业的服务</p>
          </>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <>
            <p>新模式改变行业新格局</p>
          </>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>经纪人资源管理</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>
                  通过内部邀请制度，建立自己的下级经纪人网络，整合资源，共同发展
                </p>
              </>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>数据安全</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>
                  企业级别安全打造的完整云上安全体系，全方位保障结算数据安全。
                </p>
              </>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云监控' },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>
                  分布式云环境集中监控，标记异常用户行为，保障高效平稳运行。
                </p>
              </>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '移动' },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>小程序等移动端轻办公入口，满足随时随地的业务刚需</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>经营所得</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>对接云账户平台，解决用户财税问题，合规合法开展经营</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <>
                <p>课程体系</p>
              </>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <>
                <p>一站式、全周期的免费经纪人业务培训服务，助力用户快速成长</p>
              </>
            ),
          },
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper k62iseju6pf-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <>
        <p>系统声明</p>
      </>
    ),
  },
  content: {
    className: 'content1-content k62jxsfby5-editor_css',
    children: (
      <>
        <p>
          MCNopen经纪人管理系统是由中能魔力研发的一套内部经纪人（员工）管理系统。<span
          >
            请系统用户严格遵守直播平台相关条约，
          </span>
          <span>
            共同维护直播平台有序的市场环境。本系统用户均为中能魔力的内部经纪人（员工），暂不对外开放。
          </span>
        </p>
        <p> </p>
        <p>
          <br />
        </p>
      </>
    ),
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <>
        <a style={{ color: '#fff' }} rel="noopener noreferrer" target="_blank" href='http://www.beian.miit.gov.cn/'>豫ICP备19039737号&nbsp; &nbsp; 中能魔力（河南）网络科技有限公司</a>
      </>
    ),
  },
};
